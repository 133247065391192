@import "../../app/colors.scss";
@import "../../app/screen-sizes.scss";

.playlist-card {
    width: calc(100%/6 - 2rem);
    padding: 1.5rem;
    border: 1px solid $white;
    color: $white;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    transition: box-shadow 0.25s;
    text-decoration: none;

    &:hover {
        box-shadow: 0 0 1.5rem $white;
    }

    .playlist-picture {
        width: 100%;
        border-radius: 0.5rem;
    }

    p {
        margin: 0;
    }

    .playlist-date {
        font-size: 2rem;
        text-align: center;

        &::first-letter{
            text-transform: capitalize;
        }
    }

    .playlist-nb-songs {
        font-size: 1.75rem;
    }

    @media only screen and (max-width: $lg) {
        width: calc(100%/5 - 2rem);
    }

    @media only screen and (max-width: $md) {
        width: calc(100%/4 - 2rem);
    }

    @media only screen and (max-width: $sm) {
        width: calc(100%/2 - 2rem);
    }
}