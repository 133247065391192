@import "../../app/colors.scss";
@import "../../app/screen-sizes.scss";

.bottom-section {
    padding: 2rem 20rem;

    .list-header {
        color: $purple;
        font-size: 2rem;
        display: flex;

        p {
            margin: 0;
        }
        
        .empty-space {
            width: 7%;
        }
        
        .title-space {
            width: 40%;
        }
        
        .added-date-space {
            width: 22%;
        }
    }

    .list-body {
        padding: 0;
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }

    hr {
        margin: 1rem 0;
        background: $purple;
        height: 0.1rem;;
        border: none;
    }

    @media only screen and (max-width: $lg) {
        padding: 2rem 10rem;
    }

    @media only screen and (max-width: $md) {
        padding: 2rem 5rem;
    }
}