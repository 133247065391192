@mixin top-section-playlist {
    width: 100%;
    background: linear-gradient($purple, $purple-gradient);
    height: 35rem;
    padding: 5rem 15rem;

    .playlist-info {
        height: 100%;
        width: 100%;
        display: flex;
        align-items: flex-end;
        gap: 2rem;

        .playlist-picture {
            height: 100%;
            aspect-ratio: 1/1;
            border-radius: 5px;
        }

        .playlist-detail {
            color: white;
            display: flex;
            flex-direction: column;
            gap: 3rem;

            .playlist-date {
                font-size: 6rem;
                margin: 0;
            }

            .playlist-nb-songs {
                font-size: 4rem;
                margin: 0;
            }
        }
    }

    @media only screen and (max-width: $md) {
        padding: 5rem 5rem;
        height: 30rem;
    }

    @media only screen and (max-width: $sm) {
        padding: 5rem 5rem;
        height: 25rem;
    }
}