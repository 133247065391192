@import "../../app/colors.scss";


.events-by-datesection {
    .events-date {
        color: $light-grey;
        display: flex;
        align-items: center;
        margin-bottom: 2rem;
    
        .separator {
            width: 100%;
            height: 1rem;
            background-color: $light-grey;
            height: 0.2rem;
        }
    
        h2 {
            width: fit-content;
            /* overflow: hidden; */
            white-space: nowrap;
            margin: 0 2rem;
        }
    }
    
    .events-list {
        display: flex;
        flex-direction: column;
        gap: 3rem;
    }
}