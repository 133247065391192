$background: #232323;

$dark-grey: #1C1C1C;
$white: #FFFFFF;
$purple: rgba(173, 148, 170, 1);
$purple-gradient: rgba(173, 148, 170, 0.15);
$green: #1ED760;
$red: #d33737;

$song-card: rgba(255, 255, 255, 0.041);
$song-card-selected: rgba(194, 194, 194, 0.25);
$song-card-hover: rgba(128, 128, 128, 0.25);

$song-deleted: rgba(255, 124, 124, 0.15);
$song-deleted-selected: rgba(255, 124, 124, 0.25);
$song-deleted-hover: rgba(206, 100, 100, 0.25);

$local-song-background: #292929;

$light-grey: rgb(168, 168, 168);