@import "../../app/colors.scss";

.playlist-section {
    display: flex;
    flex-direction: column;
    margin: 2rem;
    flex-wrap: wrap;

    .playlist-year-title {
        color: $green;
        font-size: 2.5rem;
        margin: 0;
    }

    .playlist-list {
        display: flex;
        gap: 2rem;
        flex-wrap: wrap;
    }

    hr {
        margin: 0;
        background: white;
        height: 0.3rem;
        margin: 1rem 0 2rem 0;
    }
}