@import "../../app/colors.scss";

$border-top-left-radius: 1rem;

.event-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: white;
    background-color: $song-card;
    border-radius: $border-top-left-radius;
    transition: background-color 0.05s;
    padding: 0 2rem 0 2rem;

    &.event-add {
        border-left: 1rem solid $green;
        
        .event-added-or-removed {
            color: $green;
        }
    }

    &.event-remove {
        border-left: 1rem solid $red;
        
        .event-added-or-removed {
            color: $red;
        }
    }

    .event-info {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        padding: 2rem 0;

        p {
            margin: 0;

            &.event-added-or-removed {
                font-size: 2rem;
                margin: 0;
            }

            &.song-title {
                font-size: 2rem;
            }
            &.song-artist {
                font-size: 1.5rem;
            }
        }
    }

    .song-image, .song-local-image {
        height: 10rem;
        border-radius: $border-top-left-radius;
    }
    
    .song-local-image {
        padding: 2rem;
        background-color: $local-song-background;

        .song-image, .song-local-image {
            height: 100%;
            border-top-left-radius: $border-top-left-radius;
        }
    }
    
}