@use 'components/components.scss';
@use 'pages/pages.scss';
@import 'app/colors.scss';
@import 'app/screen-sizes.scss';
@import 'app/mixins.scss';

html, body {
  font-size: 10px;

  @media only screen and (max-width: $md) {
    font-size: 9px;
  }

  @media only screen and (max-width: $sm) {
    font-size: 7px;
  }
}

* {
  font-family: 'Lexend';
  box-sizing: border-box;
}

.spotify-app {
  min-height: 100vh;
  background-color: $background;
}
