@import "../../app/colors.scss";
@import "../../app/screen-sizes.scss";

.events-container {
    padding: 5rem 15%;
    display: flex;
    flex-direction: column;
    gap: 3rem;

    @media only screen and (max-width: $lg) {
        padding: 5rem 10%;
    }

    @media only screen and (max-width: $sm) {
        padding: 5rem 7%;
    }

    @media only screen and (max-width: $xs) {
        padding: 5rem 5%;
    }
}