@import "../../app/colors.scss";
@import "../../app/screen-sizes.scss";

.header {
    background-color: $dark-grey;
    min-height: 4rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content:left;
    padding: 0 1rem;
    gap: 2rem;

    a{
        text-decoration: none;

        &:visited, &:link {
            color: inherit;
        }

        h1 {
            color: $green;
            font-size: 2rem;

            @media only screen and (max-width: $md) {
              font-size: 3.5rem;
            }
        }
    }

    .navbar {
        display: flex;
        gap: 1.5rem;
        
        a {
            font-size: 1.5rem;
            color: white;

            &.active {
                color: $green;
            }

            @media only screen and (max-width: $md) {
              font-size: 2.5rem;
            }
        }
    }
}