@import "../../app/colors.scss";
@import "../../app/screen-sizes.scss";

$border-top-left-radius: 1rem;

.song-row {
    display: flex;
    align-items: center;
    color: white;
    background-color: $song-card;
    border-top-left-radius: $border-top-left-radius;
    cursor: pointer;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    transition: background-color 0.05s;

    &:hover {
        background-color: $song-card-hover;
    }
    &.song-row-selected {
        background-color: $song-card-selected;
    }
    &:focus {
        outline: none;
    }
    &.song-deleted{
        background-color: $song-deleted;


        &:hover {
            background-color: $song-deleted-hover;
        }

        &.song-row-selected {
            background-color: $song-deleted-selected;
        }
    }

    .song-image, .song-local-image {
        width: 5%;
        margin-right: 2%;
        border-top-left-radius: $border-top-left-radius;

        @media only screen and (max-width: $lg) {
            width: 8%;
        }
        @media only screen and (max-width: $md) {
            width: 10%;
        }
    }

    .song-local-image {
        height: 100%;
        padding: 2rem;
        background-color: $local-song-background;

        .song-image, .song-local-image {
            width: 100%;
            border-top-left-radius: $border-top-left-radius;
        }
    }
    
    .song-title-artist {
        width: 38%;
        margin-right: 2%;
        display: flex;
        flex-direction: column;
        gap: 0.3em;


        @media only screen and (max-width: $lg) {
            width: 35%;
        }
        @media only screen and (max-width: $md) {
            width: 33%;
        }

        p {
            margin: 0;
        }

        .song-title {
            font-size: 2.5rem;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        }
        
        .song-artist {
            font-size: 1.75rem;
        }
    }

    .song-date-base {
        display: block;

        @media only screen and (max-width: $sm) {
            display: none;
        }
    }

    .song-date-simple {
        display: none;

        @media only screen and (max-width: $sm) {
            display: block;
        }
    }
    
    .song-added-at {
        width: 22%;
        font-size: 2.25rem;
        margin: 0;
        display: flex;
        align-items: center;
        gap: 3rem;

        &.full-width {
            width: fit-content;
        }

        .song-added-at-text {
            width: 100%;
        }

        .song-recently-added {
            display: flex;
            align-items: center;
            background-color: $green;
            height: 4rem;
            border-radius: 2rem;
            padding: 1rem 2rem 1rem 1.5rem;
            gap: 1rem;

            img {
                height: 100%;
                aspect-ratio: 1/1;
            }

            p {
                font-size: 1.5rem;
                color: black;
                white-space: nowrap;
            }

            @media only screen and (max-width: $md) {
                padding: 1rem 1.5rem 1rem 1.5rem;

              p {
                display: none;
              }
            }
        }
    }
    
    .song-removed-at {
        font-size: 2.25rem;
        margin: 0;
        display: flex;
        align-items: center;
        gap: 3rem;

        .song-removed-at-text {
            width: 100%;
        }
    }
}